/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDgJHpoJYcBYFPfCpdt3Gic57BYaHGiCrE",
  "appId": "1:961697041940:web:d04357c90028b27cbea101",
  "authDomain": "schooldog-i-rockdale-ga.firebaseapp.com",
  "measurementId": "G-DVDT9GCGWS",
  "messagingSenderId": "961697041940",
  "project": "schooldog-i-rockdale-ga",
  "projectId": "schooldog-i-rockdale-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-rockdale-ga.appspot.com"
}
